<template>
  <div>
    <select-shop
      :loadingButton="loadingButton"
      v-on:getData="getSerialInventoryReport"
    ></select-shop>
    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-sm-9 col-8">
              <h2 class=" font-weight-normal">
                {{ $t('inventoryReportBySerial') }}
              </h2>
              <show-date></show-date>
            </div>
            <div
              v-if="isExport"
              class="col-md-2 col-sm-3  col-4 text-right text-success"
            >
              <CButton
                id="serialExportButton"
                v-on:click="exportSerialInventoryReport()"
                block
                color="info"
              >
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}</CButton>
            </div>
          </div>
          <CRow>
            <CCol lg="2" md="4" sm="5" col="4">
              <select id="serialSelectSearchAttr" class="custom-select" v-model="searchAttr">
                <option id="serialSelectoptionAll" value="" selected>{{ $t('showProductAll') }}</option>
                <option id="serialSelectoptionProductname" value="ProductPLU.name">{{ $t('productName') }} </option>
                <option id="serialSelectoptionpluCode" value="ProductPLU.PLUCode">{{ $t('pluCode') }}</option>
                <option id="serialSelectoptioncate" value="ProductSKU.category.name">{{ $t('category') }}</option>
              </select>
            </CCol>
            <CCol lg="8" md="6" sm="5" col="5">
              <CInput id="serialKeywordInput" v-model.trim="keyword" :placeholder="searchAttr === '' ? $t('dialogSelectCategory') : $t('keyword')" :disabled="searchAttr === ''"  />
            </CCol>
            <CCol
              lg="2"
              md="2"
              sm="3"
              col="3"
              v-if="searchLoadingButton === true"
            >
              <CButton
                id="serialSearchButton"
                v-on:click="getSerialInventoryReport()"
                block
                color="success"
              >
                {{ $t('search') }}
              </CButton>
            </CCol>
            <CCol
              lg="2"
              md="2"
              sm="3"
              v-else-if="searchLoadingButton === false"
            >
              <CButton block color="success" disabled>
                <CSpinner color="white" size="sm" />
              </CButton>
            </CCol>
          </CRow>
          <hr class="mb-3 mt-0"/>
          <DataTable
            id="serialDatatable"
            :items="items"
            :fields="fields"
            hover
            border
            clickableRows
            style="white-space:nowrap;"
            @row-clicked="getDetail"
          >
          </DataTable>

          <pagination
            :meta_data="meta_data"
            v-on:next="getSerialInventoryReport"
          >
          </pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import axios from '@/services/service'
import report from '@/services/report'
import Pagination from '@/containers/Pagination'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'

export default {
  components: {
    Pagination,
    DataTable,
  },
  data() {
    return {
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      searchLoadingButton: true,
      datasearch: [],
      searchAttr: '',
      keyword: '',
    }
  },
  computed: {
    ...mapGetters(['shops']),
    isPermission() {
      return permis.findPermissionRead('inventory', this.$route.path)
    },
    isExport() {
      return permis.findPermissionExport('inventory', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId
      },
      set(newValue) {
        return this.$store.dispatch('setShop', newValue)
      },
    },

    fields() {
      return [
        {
          key: 'id',
          label: this.$i18n.t('#'),
          _classes: 'text-center font-weight-normal text-dark',
          _style: 'width:2%',
        },
        {
          key: 'PLUCode',
          label: this.$i18n.t('pluCode'),
          _style: 'width:15%',
          _classes: 'font-weight-normal text-dark',
        },
        {
          key: 'name',
          label: this.$i18n.t('productName'),
          _style: 'width:40%',
          _classes: 'font-weight-normal text-dark',
        },
        { key: 'unit', label: this.$i18n.t('unit'), _classes: 'text-left font-weight-normal text-dark' },
        { key: 'categoryName', label: this.$i18n.t('category'), _classes: 'font-weight-normal text-dark', },
        {
          key: 'quantity',
          label: this.$i18n.t('quantity'),
          _classes: 'text-right font-weight-normal text-dark',
        },
      ]
    },
    items() {
      let data = this.data
      let detail = []
      let start = this.meta_data.current_page * 50 - 50

      for (let i = 0; i < data.length; i++) {
        detail.push({
          id: start + i + 1,
          objectId: data[i].objectId || '-',
          PLUCode: data[i].PLUCode || '-',
          name: data[i].name || '-',
          unit: data[i].unit?.name || '-' + ' / ' + data[i].SKURatio || '-',
          categoryName: data[i].category?.name || '-',

          quantity: data[i].quantity || 0,
        })
      }
      return detail
    },
  },
  created() {
    this.getSerialInventoryReport()
  },
  methods: {
    getDetail(item) {
      this.$router.push('/inventory/serial/' + item.objectId)
    },
    getSerialInventoryReport(page = 1) {
      this.loadingButton = false
      this.searchLoadingButton = false

      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const params = {
        shopObjectId: shopObjectId,
        searchAttr: this.searchAttr,
        searchVal: this.keyword,
        page: page,
        limit: 50,
      }

      axios({
        url: '/inventory/v1.0/' + uid + '/serial',
        params: params,
        method: 'GET',
      })
        .then((res) => {
          this.data = res.data.data

          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0
          } else {
            this.meta_data.items = res.data.paginate.items
          }
          this.meta_data.last_page = res.data.paginate.pageCount
          this.meta_data.current_page = res.data.paginate.currentPage
          this.meta_data.itemCount = res.data.paginate.itemCount
          this.meta_data.limit = res.data.paginate.perPage
          this.searchLoadingButton = true
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
    exportSerialInventoryReport() {
      const uid = `${localStorage.getItem('shopsUid')}`
      const shopObjectId = this.shopObjectId

      const params = {
        shopObjectId: shopObjectId,
        searchAttr: this.searchAttr,
        searchVal: this.keyword,
      }

      report({
        url: '/inventory/v1.0/' + uid + '/serial/excel',
        params: params,
        method: 'GET',
        responseType: 'blob',
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'รายงานสินค้ามีซีเรียลนัมเบอร์.xlsx')
          document.body.appendChild(link)
          link.click()
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
